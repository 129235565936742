import { firebaseConfig, app, db } from "../firebase.config"
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { collection, setDoc, addDoc, get, getDoc, doc, query, where, getDocs } from "firebase/firestore";

function Test1Db() {

  // const firebaseConfig = {
  //   apiKey: "AIzaSyAaZAZuXCet_pQleMURTIc8RpRXL1Kl7Ig",
  //   authDomain: "pmaweb2023.firebaseapp.com",
  //   projectId: "pmaweb2023",
  //   storageBucket: "pmaweb2023.appspot.com",
  //   messagingSenderId: "449567587653",
  //   appId: "1:449567587653:web:b81c7f953fb8c02432122c"
  // };


  // Initialize Firebase
  // const app = initializeApp( {
  // apiKey: "AIzaSyAaZAZuXCet_pQleMURTIc8RpRXL1Kl7Ig",
  // authDomain: "pmaweb2023.firebaseapp.com",
  // projectId: "pmaweb2023",
  // storageBucket: "pmaweb2023.appspot.com",
  // messagingSenderId: "449567587653",
  // appId: "1:449567587653:web:b81c7f953fb8c02432122c",

  // });

  // const app = initializeApp(firebaseConfig);
  // const db = getFirestore(app);

  const [evntData, setevntData] = useState([])

  // function to fetch a record

  const fetchBlogs = async () => {

    console.log("inside fetch block");

    const response = doc(db, "pmaEvntData", "profile11");
    const datasnap = await getDoc(response);

    //console.log(JSON.stringify(data.name));
    console.log(datasnap.data());

    // data.docs.forEach(item=>{
    //     setevntData([...evntData,item.data()])
    // });
  }


  // function to fetch multiple records
  const fetchMulrec = async () => {

    console.log("inside multiple fetch");

    //const q = query(collection(db, "pmaEvntData"), where("age", ">", 20));
    const q = query(collection(db, "pmaEvntData"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      console.log(doc.data().eventTitle);
      console.log(doc.data().eventTkAway);
    });
  }

  // add a new record

  const addNewRec = async () => {
    console.log("inside add new records");
    const citiesRef = collection(db, "pmaEvntData");

    const resp = await setDoc(doc(citiesRef, "profile11"), {
      name: "San Francisco",
      age: 55,
    });

    console.log(resp);

  }


  useEffect(() => {
    console.log("inside use effect");
    //fetchBlogs();
    fetchMulrec();
    //addNewRec();
  }, [])

  return (
    <div>
      {
        evntData && evntData.map(blog => {
          return (
            <div>
              <h4>{blog.name}</h4>
              <p>{blog.age}</p>
            </div>
          )
        })
      }
    </div>
  );
}

export default Test1Db;