import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



function createTTData(no, name, designation) {
  return { no, name, designation };
}


const advRows = [

  createTTData('1','Mr. Bal Patil','President'),
  createTTData('2','Mr. Pradeep Tupe','Vice President'),
  createTTData('3','Dr. Sanjay Gandhi','Vice President'),
  createTTData('4','Prof. Abhijit Khurape','Hon Secretary'),
  createTTData('5','Mr. Bhagyesh Abhyankar','Member'),
  createTTData('6','Mr. Avinash Arvikar','Member'),
  createTTData('7','Dr. Ravikumar Chitnis','Member'),
  createTTData('8','Mr. Nikhil Dhage','Member'),
  createTTData('9','Mr. Sunil Harpude','Member'),
  createTTData('10','Mr. Rahul Joshi','Member'),
]

function TrustTAccordian() {

    return(
        
    <div>
      <Accordion sx={{border: 3, borderColor: 'primary.main'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" sx={{color: 'primary.blackC'}}>
            <b> Trustee / Executive Committee </b></Typography>

        </AccordionSummary>
        <AccordionDetails>
            <Divider></Divider>
          <Typography variant="h6" sx={{color: 'primary.blackC'}}>
            
            <p>Day to day activities of PMA are managed by Trustee /Executive Committee, comprising of President, 2 Vice presidents, Honorary Secretary , Jt Honorary Secretary and Executive Committee Members, under the guidance of Advisory Committee. Following are the current Trustee/Executive Committee Members. Any member who can contribute in the form of Time, Expertise, Knowledge etc can become Executive Committee Member and use his expertise to lead activities of PMA from the front.
              </p>        
          </Typography>

          <p></p>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell >No</TableCell>
                  <TableCell >Name</TableCell>
                  <TableCell >Designation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advRows.map((row) => (
                  <TableRow
                    key={row.no}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.no}
                    </TableCell>
                    <TableCell >{row.name}</TableCell>
                    <TableCell >{row.designation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  
    );
}
export default TrustTAccordian;