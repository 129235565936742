import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(year, president, hon_secretary) {
  return { year, president, hon_secretary };
}

const rows = [
    createData('1972-73','Mr CS Kirloskar','Mr VP Deo'),
    createData('1973-75','Mr CV Joag','Mr SH Talavlikar'),
    createData('1975-80','Mr SH Talavlikar','Mr KK Taimni'),
    createData('1980-82','Dr MV Patwardhan','Mr VK Seth'),
    createData('1982-83','Dr MV Patwardhan','Mr VB Mainkar'),
    createData('1983-85','Mr PD Dixit','Mr VK Seth'),
    createData('1985-86','Dr PM Parkhe','Mr VK Seth'),
    createData('1986-87','Dr PM Parkhe','Mr VB Mainkar'),
    createData('1986-87','Dr PM Parkhe','Mr VB Mainkar'),
    createData('1987-89','Mr Viren Seth','Mr VB Mainkar'),
    createData('1989-92','Mr GS Talaulikar','Mr VB Mainkar'),
    createData('1992-96','Mr Dilip Borawake','Mr VB Mainkar'),
    createData('1996-97','Mr Neeraj Handa','Mr VS Ukidave'),
    createData('1997-05','Dr PC Shejwalkar','Dr PP Bawale'),
    createData('2005-08','Mr Raja Bellare','Mr Pradeep Tupe'),
    createData('2008-10','Prof (Dr) Mangesh Karad','Mr Pradeep Tupe'),
    createData('2011-13','Dr Ganesh Natrajan','Mr Pradeep Tupe'),
    createData('2013-14','Mr Niket Karajgi (actng)','Mr Pradeep Tupe'),
    createData('2014-15','Mr Bal Patil','Mr Kiran Nawathe'),
    createData('2015-16','Mr Bal Patil','Mr Pravin Potdar'),
    createData('2016-21','Mr Bal Patil','Mr Sunil Harpude'),
    createData('2021-23','Mr Bal Patil','Prof Abhijit Khurape'),
];

export default function SoHTableComp() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >Year</TableCell>
            <TableCell >President</TableCell>
            <TableCell >Hon Secretary</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.year}
              </TableCell>
              <TableCell >{row.president}</TableCell>
              <TableCell >{row.hon_secretary}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}